import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Outlet,
  useRouteError,
  useLoaderData,
} from "react-router-dom";
//import pages
import Home, { HomeLoader } from "./pages/Home";
import ChristPassion, { ChristPassionLoader } from "./pages/ChristPassion";
import DivineMercy, { GreatestShieldLoader } from "./pages/DivineShield";
import GreatestWeapon, { DivineWeaponLoader } from "./pages/DivineWeapon";
import PowerfulLessons, { PowerfulLessonsLoader } from "./pages/PowerfulLessons";
import HeavenlyTraces, { HeavenlyTracesLoader } from "./pages/HeavenlyTraces";
import HomeDM from "./pages/HomeDM";
import GlobalArena, { GlobalArenaLoader } from "./pages/GlobalBattleArena";
import HeavenlyPower, { HeavenlyPowerLoader } from "./pages/HeavenlyPower";
import Support, { SupportLoader } from "./pages/Support";
import { HtArticle, HtArticleLoader, DwArticle, DwArticleLoader } from "./pages/Article";
//import components
import { useGlobalContext } from "./Context";
import Navbar from "./pages/components/navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";

// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    ReactGA.initialize("UA-243936063-1");
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route basename="/" element={<Root />}>
        {/* <Route
          path="/:lang?"
          loader={(params) => HomeLoader(params)}
          element={<Home />}
          index
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/ht_article/:name?/:lang?"
          loader={(params) => HtArticleLoader(params)}
          element={<HtArticle />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/dw_article/:name?/:lang?"
          loader={(params) => DwArticleLoader(params)}
          element={<DwArticle />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/home/:lang?"
          loader={(params) => HomeLoader(params)}
          element={<Home />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/home-dm/:lang?"
          loader={(params) => HomeLoader(params)}
          element={<HomeDM />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/the-story/:lang?"
          loader={(params) => ChristPassionLoader(params)}
          element={<ChristPassion />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/medjugorje/:lang?"
          loader={(params) => GlobalArenaLoader(params)}
          element={<GlobalArena />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/our-divine-shield/:lang?"
          loader={(params) => GreatestShieldLoader(params)}
          element={<DivineMercy />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/our-divine-weapon/:lang?"
          loader={(params) => DivineWeaponLoader(params)}
          element={<GreatestWeapon />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/letters-and-scrolls/:lang?"
          loader={(params) => PowerfulLessonsLoader(params)}
          element={<PowerfulLessons />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/heavenly-traces/:lang?"
          loader={(params) => HeavenlyTracesLoader(params)}
          element={<HeavenlyTraces />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/heavenly-power/:lang?"
          loader={(params) => HeavenlyPowerLoader(params)}
          element={<HeavenlyPower />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/support-zeal/:lang?"
          loader={(params) => SupportLoader(params)}
          element={<Support />}
          errorElement={<ErrorBoundary />}
        /> */}
        <Route path="/*" loader={(params) => HomeLoader(params)} element={<ErrorBoundary />} />
      </Route>
    )
  );

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="description"
          content="A research-based organization spreading information regarding Christian related events."
        />
        <meta
          name="keywords"
          content={[
            "Jesus",
            "Jesús",
            "God",
            "Christian",
            "Catholic",
            "Mercy",
            "misericordia",
            "Dios",
            "Shield",
            "Lord",
            "Love",
            "zeal",
            "king",
            "Dios",
            "amor",
          ]}
        />
      </Helmet>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

const Root = () => {
  const { homePage } = useGlobalContext();
  const [isHome, setIsHome] = useState(true);

  useEffect(() => {
    setIsHome(homePage);
  }, [homePage]);

  return (
    <>
      {!isHome && <Navbar />} <Outlet />
    </>
  );
};

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  return (
    <div className="error-page">
      <div className="error-en">
        <i className="fa-light fa-cloud-exclamation"></i>
        <p>Apologies, we maybe currently conducting maintenance on the application. Sorry for this inconvenience.</p>
      </div>
      <div className="error-sp">
        <i className="fa-light fa-cloud-exclamation"></i>
        <p>
          Lo sentimos, es posible que estemos haciendo mantenimiento en la aplicación. Lo siento por este inconveniente
          ocasionado.
        </p>
      </div>
    </div>
  );
}

export default App;
